<!--信息通知布局-->
<template>
  <div id="infoLayout">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#infoLayout {
  width: 100%;
  height: 100%;
}
</style>